const getters = {
  gridList(state) {
    return state.gridList;
  },
  user(state) {
    return state.user;
  },
  searchQuery(state) {
    return state.searchQuery;
  },
  tblLoader(state) {
    return state.tblLoader;
  },
  sortBy(state) {
    return state.sortBy;
  },
  sortOrder(state) {
    return state.sortOrder;
  },
  isLoading(state) {
    return state.isLoading;
  },
};

export default getters;
