<template>
  <b-dropdown
    id="notificationDropdown"
    ref="notificationDropdown"
    right
    no-caret
    lazy
    variant="link"
    toggle-class="text-decoration-none"
    class="notification-dropdown"
    :menu-class="['mt-2']"
  >
    <template #button-content>
      <i class="icon-bell bell-notification" role="button"></i>
      <span
        class="bullet animation-blink position-absolute top-0 start-50"
        v-if="isNotificationActive"
      ></span>
    </template>
    <b-dropdown-header id="dropdown-header-label">
      <div class="d-flex justify-content-between align-items-center">
        <span class="h7 m-0 p-0">Notifications</span>
        <b-link
          @click="seeAllHistory"
          :class="{
            'brand-navi-selected': $route.name == 'version-history',
          }"
          v-if="$route.name != 'version-history'"
          >See all</b-link
        >
      </div>
    </b-dropdown-header>
    <template v-for="(history, index) in versionHistory">
      <b-dropdown-item-button :key="'history_' + history.id" v-if="index < 5">
        <div class="d-flex align-items-center">
          <div class="notification-icon">
            <i :class="history.icon ? history.icon : 'icon-globe'"></i>
          </div>
          <div class="notification-description">
            <span class="h7 p-0 m-0 release-date">{{
              history.deploymentDate | deploymentDate
            }}</span>
            <div class="body-3">
              <span class="version">{{ history.version }}</span>
              <span class="project-title">{{ history.projectTitle }}</span>
            </div>
          </div>
        </div>
      </b-dropdown-item-button>
    </template>
  </b-dropdown>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "HistoryNotification",
  computed: {
    ...mapGetters(["versionHistory"]),
    isNotificationActive() {
      return this.user && this.user.activeNotification;
    },
  },
  methods: {
    seeAllHistory() {
      this.$refs.notificationDropdown.hide(true);
      if (this.$router.name == "version-history") return;
      this.$router.push({ name: "version-history" });
    },
  },
  filters: {
    deploymentDate: function (value) {
      return moment(value).format("MMM DD");
    },
  },
};
</script>
