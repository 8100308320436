import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import axios from "axios";
import VueMeta from "vue-meta";
import i18n from "@/plugins/i18n";
import IdleVue from "@/plugins/idle-vue";
import helpers from "@/plugins/helpers";

import "./assets/styles/app.scss";

import Vuelidate from "vuelidate";
import Editor from "@tinymce/tinymce-vue";

import mixins from "@/shared/mixins";

import { Calendar, DatePicker } from "v-calendar";

import VueApexCharts from "vue-apexcharts";

import PortalVue from "portal-vue";

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false;

Vue.use(VueMeta);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);

Vue.use(Vuelidate);

Vue.component("editor", Editor);

Vue.mixin(mixins);

Vue.component("VCalendar", Calendar);
Vue.component("VDatePicker", DatePicker);

export const eventBus = new Vue(); // creating an event bus.

Vue.use(IdleVue, {
  eventEmitter: eventBus,
  idleTime: parseInt(process.env.VUE_APP_IDLETIME) * 60000, // 60000 = 1 minute
});

Vue.directive("clickoutside", {
  inserted: (el, binding, vnode) => {
    // assign event to the element
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el == event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        vnode.context[binding.expression](event);
      }
    };
    // register click and touch events
    document.body.addEventListener("click", el.clickOutsideEvent);
    document.body.addEventListener("touchstart", el.clickOutsideEvent);
  },
  unbind: function (el) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener("click", el.clickOutsideEvent);
    document.body.removeEventListener("touchstart", el.clickOutsideEvent);
  },
  stopProp(event) {
    event.stopPropagation();
  },
});

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.use(PortalVue);
Vue.use(helpers);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
