const Index = () => import("./Index");
const EverydayPricing = () => import("./components/EverydayPricing");
const PromotionalPricing = () => import("./components/PromotionalPricing");
// const FlyerPricing = () => import("./components/FlyerPricing");
const FlatFlyerPricing = () => import("./components/FlatFlyerPricing");
const FlyerPricingImport = () => import("./components/FlyerPricingImport");
const ActivePromotion = () => import("./components/ActivePromotion");
const History = () => import("./components/History");
const PromotionalPricingImport = () =>
  import("./components/PromotionalPricingImport");

const EverydayPricingImport = () =>
  import("./components/EverydayPricingImport");

import auth from "@/router/middleware/auth";
import checkUserPermission from "@/router/middleware/checkUserPermission";
import maintenance from "@/router/middleware/maintenance";

const pricingRoute = {
  path: "",
  component: Index,
  children: [
    {
      path: "/everyday-pricing",
      name: "everyday-pricing",
      meta: {
        label: "Everyday Pricing",
        controlLink: "everyday-pricing",
        languageSlug: "everyday-pricing",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Pricing Manager",
        moduleLanguageSlug: "pricing-manager",
        permissionName: "everyday-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: EverydayPricing,
    },
    {
      path: "/everyday-pricing-import",
      name: "everyday-pricing-import",
      meta: {
        label: "Import Pricing",
        controlLink: "everyday-pricing-import",
        languageSlug: "everyday-pricing-import",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Pricing Manager",
        moduleLanguageSlug: "everyday-pricing",
        permissionName: "everyday-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: EverydayPricingImport,
    },
    {
      path: "/promotional-pricing",
      name: "promotional-pricing",
      meta: {
        label: "Promotional Pricing",
        controlLink: "promotional-pricing",
        languageSlug: "promotional-pricing",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Pricing Manager",
        moduleLanguageSlug: "pricing-manager",
        permissionName: "promotional-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: PromotionalPricing,
    },
    {
      path: "/promotional-pricing-import",
      name: "promotional-pricing-import",
      meta: {
        label: "Promotional Pricing Import",
        controlLink: "promotional-pricing-import",
        languageSlug: "promotional-pricing-import",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Promotional Pricing",
        moduleLanguageSlug: "promotional-pricing",
        permissionName: "promotional-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: PromotionalPricingImport,
    },
    // {
    //   path: "/flyer-pricing",
    //   name: "flyer-pricing",
    //   meta: {
    //     label: "Flyer Pricing",
    //     controlLink: "flyer-pricing",languageSlug: "flyer-pricing",
    //     module: "pricing",
    //     moduleMenuId: "pricingMenu",
    //     moduleLabel: "Pricing Manager",moduleLanguageSlug: "pricing-manager",
    //     permissionName: "flyer-pricing-access",
    //     middleware: [maintenance, auth, checkUserPermission],
    //   },
    //   component: FlyerPricing,
    // },
    {
      path: "/flyer-pricing-import",
      name: "flyer-pricing-import",
      meta: {
        label: "Flyer Pricing",
        controlLink: "flyer-pricing-import",
        languageSlug: "flyer-pricing",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Pricing Manager",
        moduleLanguageSlug: "pricing-manager",
        permissionName: "flyer-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: FlyerPricingImport,
    },
    {
      path: "/flat-flyer-pricing",
      name: "flat-flyer-pricing",
      meta: {
        label: "Flat Flyer Pricing",
        controlLink: "flat-flyer-pricing",
        languageSlug: "flat-flyer-pricing",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Flyer Pricing",
        moduleLanguageSlug: "flyer-pricing",
        permissionName: "flat-flyer-pricing-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: FlatFlyerPricing,
    },
    {
      path: "/active-promotion",
      name: "active-promotion",
      meta: {
        label: "Active Promotions",
        controlLink: "active-promotion",
        languageSlug: "active-promotions",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLabel: "Pricing Manager",
        moduleLanguageSlug: "pricing-manager",
        permissionName: "active-promotion-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: ActivePromotion,
    },
    {
      path: "/pricing-history",
      name: "pricing-history",
      meta: {
        label: "History",
        controlLink: "pricing-history",
        languageSlug: "pricing-history",
        module: "pricing",
        moduleMenuId: "pricingMenu",
        moduleLanguageSlug: "pricing-manager",
        moduleLabel: "Pricing Manager",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: History,
    },
  ],
};

export default pricingRoute;
