import ajax from "@/customApi";
import config from "../../../../config";
import { eventBus } from "@/main";
const { path, messages } = config;
const qs = require("qs");

const service = {
  getTranslationModules,
  getTranslationFields,
  getTranslationFieldDetails,
  updateTranslationFieldDetails,
  getVHistoryData,
  getVHistoryById,
  manageHistoryVersion,
};

async function getTranslationModules(params) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getTranslationModules, {
      name: "getTranslationModules",
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getTranslationModules",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Translations.vue",
        actionName: "getTranslationModules",
        exceptionStackTrace: `API : ${path.getTranslationModules}`,
        moduleName: "Translations",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getTranslationFields(params) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getTranslationFields, {
      name: "getTranslationFields",
      params: params,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getTranslationFields",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Translations.vue",
        actionName: "getTranslationFields",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getTranslationFields}`,
        moduleName: "Translations",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getTranslationFieldDetails(params) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getTranslationFieldDetails, {
      name: "getTranslationFieldDetails",
      params: params,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getTranslationFieldDetails",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Translations.vue",
        actionName: "getTranslationFieldDetails",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getTranslationFieldDetails}`,
        moduleName: "Translations",
        isApi: 0,
      },
    });
    return [];
  }
}

async function updateTranslationFieldDetails(params) {
  try {
    const { data } = await ajax.post(path.updateTranslationFieldDetails, {
      name: "updateTranslationFieldDetails",
      body: params.translationFieldDetails,
      params: { fieldId: params.fieldId, userId: params.userId },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "updateTranslationFieldDetails",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "Translations.vue",
        actionName: "updateTranslationFieldDetails",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.updateTranslationFieldDetails}`,
        moduleName: "Translations",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getVHistoryData(params) {
  let { userId } = params;
  delete params.userId;
  try {
    const { data } = await ajax.get(path.getVersionHistory, {
      name: "getVersionHistory",
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getVersionHistory",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "VersionHistoryList.vue",
        actionName: "getVersionHistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getVersionHistory}`,
        moduleName: "Version History List",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getVHistoryById(params) {
  try {
    const { data } = await ajax.get(path.getVersionHistory, {
      name: "version history edit",
      params: { id: params.id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    // if (data && data.length == 1 && data[0].id == params.id) {
    //   return data[0];
    // } else {
    //   return data.filter((item) => item.id == params.id);
    // }
    // return data;
    return data.find((item) => item.id == params.id);
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return {};
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "version history edit",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "getVHistoryById",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getVersionHistory}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return {};
  }
}

async function manageHistoryVersion(versionhistory) {
  let { userId } = versionhistory;

  try {
    var data = await ajax.post(path.manageVersionHistory, {
      name: "versionhistory",
      body: versionhistory,
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "versionhistory",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "VersionHistoryList.vue",
        actionName: "versionhistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.manageVersionHistory}`,
        moduleName: "Version History List",
        isApi: 0,
      },
    });
    return [];
  }
}

export default service;
