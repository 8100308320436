import service from "../service";

const actions = {
  async getSiteTags({ commit }, payload) {
    let response = await service.getSiteTags(payload);
    commit("setItem", { resource: "siteTags", payload: response.data });
  },
  async addSiteTag({ commit }, payload) {
    commit("setItem", { resource: "isAddNewTagFailed", payload: false });
    commit("setItem", { resource: "isAddNewTagFailedError", payload: "" });
    let response = await service.addSiteTag(payload);
    if (response.status == 0) {
      commit("setItem", { resource: "isAddNewTagFailed", payload: true });
      commit("setItem", {
        resource: "isAddNewTagFailedError",
        payload: response.error,
      });
    }
    return response;
  },
  async deleteSiteTag(_, payload) {
    let response = await service.deleteSiteTag(payload);
    return response;
  },
  async getHistory({ commit }, payload) {
    let response = await service.getHistory(payload);
    commit("setItem", { resource: "history", payload: response });
  },
  async getTagHistory({ commit }, payload) {
    let response = await service.getTagHistory(payload);
    if (response.status == 1)
      commit("setItem", {
        resource: "tagManagerId",
        payload: response.data.Id,
      });
    return response;
  },
  async submitTagForm(_, payload) {
    let response = await service.submitTagForm(payload);
    return response;
  },
  async getExportPreviewData(_, payload) {
    let response = await service.getExportPreviewData(payload);
    return response;
  },
  async updateStatus(_, payload) {
    await service.updateStatus(payload);
  },
};

export default actions;
