<template>
  <div class="top-navigation">
    <div
      class="bg-white desktop-nav d-flex align-items-center justify-content-end shadow-sm"
    >
      <b-dropdown
        v-if="userPanel && showLangDropdown"
        id="lang-dropdown"
        block
        menu-class="w-100 scrollable"
        no-caret
        lazy
        variant="brand-dropdown"
        :toggle-class="['text-capitalize']"
      >
        <template #button-content>
          <span class="d-flex align-items-center">
            <i class="icon-globe mr-2"></i
            ><span class="body2">{{ selectedLangText }}</span>
          </span>
        </template>
        <template v-for="(language, i) in languages">
          <b-dropdown-item
            :key="i"
            v-if="selected_language != language.languageCode"
            @click="setSelectedLang(language)"
            >{{ language.languageName }}</b-dropdown-item
          >
        </template>
      </b-dropdown>
      <history-notification />
      <b-dropdown
        id="profileDropdown"
        :text="profileInitials"
        right
        no-caret
        lazy
        variant="brand-primary"
        :toggle-class="[
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'rounded-circle',
          'text-uppercase',
        ]"
        :menu-class="['mt-2']"
      >
        <b-dropdown-item :to="{ name: 'profile' }">{{
          userPanel ? $t(`general.profile`) : "Profile"
        }}</b-dropdown-item>
        <b-dropdown-item
          :to="userPanel ? { name: 'admin-home' } : { name: 'home' }"
          v-if="isAdmin"
          >{{
            userPanel ? $t(`general.admin-panel`) : "User Panel"
          }}</b-dropdown-item
        >
        <b-dropdown-item :to="{ name: 'version-history' }" v-if="userPanel">
          {{ $t(`general.version-history`) }}
        </b-dropdown-item>
        <b-dropdown-item @click="logout">{{
          userPanel ? $t(`general.logout`) : "Logout"
        }}</b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      class="mobile-nav d-flex justify-content-between bg-brand-dark shadow-sm px-4"
    >
      <div class="d-flex align-items-center justify-content-center">
        <b-img
          src="/media/icons/mobile-brand-image.svg"
          class="brand-image"
          alt="Responsive image"
        ></b-img>
        <h6
          v-if="!userPanel"
          class="mb-0 mt-1 ml-1 text-white font-weight-bold"
        >
          Admin
        </h6>
      </div>

      <div class="d-flex align-items-center justify-content-center">
        <b-dropdown
          v-if="userPanel && showLangDropdown"
          id="lang-dropdown"
          size="sm"
          menu-class="scrollable"
          no-caret
          right
          lazy
          variant="link"
          :toggle-class="['text-decoration-none', 'text-white']"
        >
          <template #button-content>
            <i class="icon-globe"></i>
          </template>
          <template v-for="(language, i) in languages">
            <b-dropdown-item
              :key="i"
              :class="{ active: selected_language == language.languageCode }"
              @click="setSelectedLang(language)"
              >{{ language.languageName }}</b-dropdown-item
            >
          </template>
        </b-dropdown>
        <history-notification />
        <i
          class="text-white"
          :class="this.isMobileNavOpen ? 'icon-close' : 'icon-hanburger'"
          @click="isMobileNavOpen = !isMobileNavOpen"
        ></i>
      </div>
    </div>
    <Transition>
      <div class="navigation-links" v-if="isMobileNavOpen">
        <navigation-links
          :navigationMenu="navigationMenu"
          isMobile
          :isAdminNavigation="!userPanel"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { eventBus } from "../main";
import NavigationLinks from "./NavigationLinks.vue";
import HistoryNotification from "./HistoryNotification.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import config from "../../config";
import { forEach } from "lodash";

const { userType, navigationMenu, adminNavigationMenu } = config;

export default {
  components: { NavigationLinks, HistoryNotification },
  name: "Navigation",
  created() {
    eventBus.$on("close-mobilenav", () => {
      this.isMobileNavOpen = false;
    });
  },
  data() {
    return {
      isMobileNavOpen: false,
      showLangDropdown: true,
      selected: null,
      userType: userType,
      // navigationMenu: config.navigationMenu,
    };
  },
  props: {
    userPanel: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isMobileNavOpen(newValue) {
      if (newValue) {
        document.body.classList.add("overflow-hidden", "vh-100");
      } else {
        document.body.classList.remove("overflow-hidden", "vh-100");
      }

      eventBus.$emit("open-mobilenav", newValue);
    },
  },
  computed: {
    ...mapGetters("auth", ["userModulerPermission"]),
    ...mapGetters(["languages", "selected_language"]),
    selectedLangText() {
      let selectedLang = this.languages.find(
        (item) => item.languageCode == this.selected_language
      );
      return selectedLang.languageName;
    },
    profileInitials() {
      if (this.user == null) return "";
      return (
        this.user.firstName.substring(0, 1) +
        "" +
        this.user.lastName.substring(0, 1)
      );
    },
    isAdmin() {
      if (this.user == null) return 0;
      return this.user.userType == this.userType.adminUser;
    },
    navigationMenu() {
      if (this.userPanel) {
        let _navigationMenu = Object.assign({}, navigationMenu);
        if (this.userModulerPermission) {
          forEach(_navigationMenu, (menu) => {
            if (menu.module == "pricing") {
              let isAllowed = false;
              forEach(
                [
                  "active-promotion-access",
                  "everyday-pricing-access",
                  "flat-flyer-pricing-access",
                  "flyer-pricing-access",
                  "promotional-pricing-access",
                ],
                (module) => {
                  if (this.userModulerPermission[module]) {
                    isAllowed = true;
                    return false;
                  }
                }
              );

              menu.isAllowed = isAllowed;

              forEach(menu.routes, (route) => {
                if (route.slug == "pricing-history") {
                  let tmp_modulerPermission = Object.assign(
                    {},
                    this.userModulerPermission
                  );

                  forEach(
                    [
                      "flat-flyer-pricing-access",
                      "active-promotion-access",
                      "tag-manager-access",
                      "store-location-access",
                      "recycle-fee-access",
                    ],
                    (module) => {
                      delete tmp_modulerPermission[module];
                    }
                  );

                  let historyAllowed = Object.values(
                    tmp_modulerPermission
                  ).some((value) => value === true);

                  // route.isAllowed = menu.isAllowed;
                  route.isAllowed = historyAllowed;
                } else if (isAllowed == false) {
                  route.isAllowed = false;
                } else {
                  route.isAllowed =
                    this.userModulerPermission[route.permissionName];
                }

                if (route.submenu) {
                  forEach(route.submenu, (submenu_route) => {
                    if (route.isAllowed == false) {
                      submenu_route.isAllowed = false;
                    } else {
                      submenu_route.isAllowed =
                        this.userModulerPermission[
                          submenu_route.permissionName
                        ];
                    }
                  });
                }
              });
            } else if (menu.module == "tag") {
              if (this.userModulerPermission["tag-manager-access"] == true) {
                menu.isAllowed = true;
              } else {
                menu.isAllowed = false;
              }
            }
          });
        }
        return _navigationMenu;
      } else {
        let _navigationMenu = adminNavigationMenu;
        return _navigationMenu;
      }
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions(["getTranslations", "getVersionHistory"]),
    async setSelectedLang(params) {
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.$i18n.locale = params.languageCode;

      await this.getTranslations({ langId: params.languageId });

      this.getVersionHistory(params.languageId);

      this.$i18n.setLocaleMessage(
        params.languageCode,
        this.translations[params.languageCode]
      );

      this.generalSetItem({
        resource: "selected_language",
        payload: params.languageCode,
      });
      this.generalSetItem({ resource: "isLoading", payload: false });
    },
  },
};
</script>
