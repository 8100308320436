const getters = {
  pricingManagerId: (state) => state.pricingManagerId,
  activePromotions: (state) => state.activePromotions,
  history: (state) => state.history,
  mode: (state) => state.mode,
  preFilledData: (state) => state.preFilledData,
  validFlatFlyerData: (state) => state.validFlatFlyerData,
  errorFlatFlyerData: (state) => state.errorFlatFlyerData,
  validFlyerImportData: (state) => state.validFlyerImportData,
  validPromotionalImportData: (state) => state.validPromotionalImportData,
  validEverydayImportData: (state) => state.validEverydayImportData,
};

export default getters;
