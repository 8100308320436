import ajax from "@/customApi";
const qs = require("qs");
import config from "../../../../config";
import { eventBus } from "@/main";
const { path, responseStatus, messages } = config;
import { orderBy } from "lodash";

const service = {
  getAll,
  manageSite,
  getById,
  deleteById,
  getStoreList,
  getNewSiteId,
  getStorePermission,
  updateStorePermission,
  updateStatus,
  verifySiteApiKeys,
  getGraphqlPathVersion,
};

async function getAll(params = {}) {
  let { userId } = params;

  delete params.userId;

  try {
    const { data } = await ajax.get(path.getDetailStoresList, {
      name: "stores",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "stores",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Listing.vue",
        actionName: "getAll",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getDetailStoresList}`,
        moduleName: "Listing",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getById(id) {
  try {
    const { data } = await ajax.get(path.getDetailStoresList, {
      name: "stores",
      params: { siteId: id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return {};
  }
}

async function manageSite(store) {
  try {
    var { data } = await ajax.post(path.manageSite, {
      name: "create new site",
      body: store,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return {};
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "create new site",
      body: {
        id: 0,
        userId: store.userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "manageSite",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.manageSite}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return {};
  }
}

async function deleteById(id) {
  try {
    const { data } = await ajax.get(path.deleteStore, {
      name: "store delete",
      body: { id: id },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return {};
  }
}

async function getStoreList(params) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getStoresList, {
      name: "store list",
    });

    const sortedSites = orderBy(
      data,
      [(site) => site.storeName.toLowerCase()],
      ["asc"]
    );

    return sortedSites;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "store list",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "getStoreList",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getStoresList}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getNewSiteId(params) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getNewSiteId, {
      name: "get new siteId",
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "get new siteId",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "getNewSiteId",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getNewSiteId}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getStorePermission(id) {
  try {
    const { data } = await ajax.get(path.getStorePermission, {
      name: "getStorePermission",
      params: { siteId: id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return [];
  }
}

async function updateStorePermission(params) {
  try {
    const { data } = await ajax.put(path.updateStorePermission, {
      name: "updateStorePermission",
      body: params,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return [];
  }
}

async function updateStatus(params) {
  try {
    const { data } = await ajax.put(path.updateStoreStatus, {
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "updateStatus",
      body: {
        id: 0,
        userId: params.loginId,
        exceptionMessage: error.response.data,
        controllerName: "Listing.vue",
        actionName: "updateStatus",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.updateStoreStatus}`,
        moduleName: "Listing",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getGraphqlPathVersion(params) {
  let { userId } = params;
  delete params.userId;
  try {
    const { data, statusCode } = await ajax.get(path.getgraphqlpathversion, {
      name: "getGraphqlPathVersion",
    });
    if (statusCode == responseStatus.Success) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getGraphqlPathVersion",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "getGraphqlPathVersion",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getgraphqlpathversion}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return [];
  }
}

async function verifySiteApiKeys(params) {
  let { userId } = params;
  let { _siteApikeys } = params;
  delete params.userId;
  try {
    const { data } = await ajax.post(path.graphgqlsiteverification, {
      name: "verifySiteApiKeys",
      body: _siteApikeys,
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "verifySiteApiKeys",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "verifySiteApiKeys",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.graphgqlsiteverification}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return [];
  }
}

export default service;
