import service from "../service";

const actions = {
  async getSiteSettings(_, id) {
    const result = await service.getSiteSettings(id);

    return result[0];
  },
  async manageSiteSettings(_, siteSettings) {
    const response = await service.manageSiteSettings(siteSettings);
    return response;
  },
  async getSiteVendorCategory({ commit }, id) {
    try {
      const data = await service.getSiteVendorCategory(id);

      commit("setItem", {
        resource: "vendorCategories",
        payload: data,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async manageSiteVendorCategoryMapping(_, payload) {
    const response = await service.manageSiteVendorCategoryMapping(payload);
    return response;
  },
};

export default actions;
