const Index = () => import("./index");
const StoreList = () => import("./components/Listing");
const Create = () => import("./components/Create");
const Setting = () => import("./components/Setting");
// const Analytics = () => import("./components/Analytics");

import auth from "@/router/middleware/auth";
import maintenance from "@/router/middleware/maintenance";
import isAdmin from "@/router/middleware/isAdmin";

const sitesRoute = {
  path: "sites",
  meta: { label: "Sites" },
  component: Index,
  children: [
    {
      path: "/",
      name: "sites-list",
      meta: {
        label: "All Sites",
        controlLink: "sites-list",
        module: "sites",
        moduleMenuId: "siteMenu",
        moduleLabel: "Site Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: StoreList,
    },
    // {
    //   path: ":id/edit",
    //   name: "edit-stores",
    //   component: Create,
    //   meta: {
    //     label: "Edit Store",
    //     controlLink: "editStoreLink",
    //     module: "stores",
    //   },
    // },
    {
      path: "create",
      name: "create-site",
      meta: {
        label: "Create Site",
        controlLink: "sites-create",
        module: "sites",
        moduleMenuId: "siteMenu",
        moduleLabel: "Site Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: Create,
    },
    {
      path: "settings",
      name: "sites-settings",
      meta: {
        label: "Setting",
        controlLink: "sites-setting",
        module: "sites",
        moduleMenuId: "siteMenu",
        moduleLabel: "Site Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: Setting,
    },
    // {
    //   path: "analytics",
    //   name: "store-analytics",
    //   meta: {
    //     label: "Analytics",
    //     controlLink: "storeAnalyticsLink",
    //     module: "stores",
    //   },
    //   component: Analytics,
    // },
  ],
};

export default sitesRoute;
