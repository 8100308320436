import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store";

Vue.use(VueI18n);

const messages = store.getters["translations"];

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages,
});

export default i18n;
