import config from "../../../config";

const { userType } = config;

import { forEach } from "lodash";

export default function checkUserPermission({ to, next, store }) {
  let user = store.getters["auth/user"];
  // let defaultLandingRoute = store.getters["auth/defaultLandingRoute"];
  let userModulerPermission = store.getters["auth/userModulerPermission"];
  let routeMeta = to.meta;

  if (user) {
    if (user.userType != userType.adminUser) {
      let isAllowed = false;

      if (to.name == "pricing-history") {
        forEach(
          [
            "everyday-pricing-access",
            "flyer-pricing-access",
            "promotional-pricing-access",
          ],
          (module) => {
            if (userModulerPermission[module]) {
              isAllowed = true;
              return false;
            }
          }
        );
      } else if (to.name == "tag-history") {
        if (userModulerPermission["tag-manager-access"] == true) {
          isAllowed = true;
        }
      } else if (to.name == "help") {
        isAllowed = true;
      } else {
        let permissionName = routeMeta.permissionName;
        isAllowed = userModulerPermission[permissionName];
        if (
          to.name == "flat-flyer-pricing" &&
          userModulerPermission["flyer-pricing-access"] == false
        ) {
          isAllowed = false;
        } else if (
          to.name == "everyday-pricing-import" &&
          userModulerPermission["everyday-pricing-access"] == false
        ) {
          isAllowed = false;
        } else if (
          to.name == "promotional-pricing-import" &&
          userModulerPermission["promotional-pricing-access"] == false
        ) {
          isAllowed = false;
        }
      }

      if (isAllowed) {
        return next();
      } else {
        return next({
          name: "permission-denied",
        });
      }
    }
  } else {
    store.commit("setItem", { resource: "isLoading", payload: false });
    store.commit("auth/LogOut");
    return next({
      name: "login",
    });
  }

  return next();
}
