import { eventBus } from "@/main";
import ajax from "@/customApi";
const qs = require("qs");
import config from "../../../config";

const { path, tagManagerConfig, messages, responseStatus } = config;

const service = {
  getHistory,
  getSiteTags,
  addSiteTag,
  deleteSiteTag,
  getExportPreviewData,
  submitTagForm,
  getTagHistory,
  updateStatus,
};

async function getSiteTags(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.get(path.tm_getTags, {
      name: "getSiteTags",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return { status: 1, data: data };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: [], error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getSiteTags",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "getSiteTags",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_getTags}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: [], error: error };
  }
}

async function addSiteTag(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.tm_postNewTag, {
      name: "addSiteTag",
      body: params,
    });
    let result = JSON.parse(data[0].jsonResult)[0];

    if (result.Code == tagManagerConfig.alreadyExists) {
      return { status: 0, data: null, error: messages.tagAlreadyExists };
    } else if (result.Code == tagManagerConfig.limitExceeds) {
      return { status: 0, data: null, error: messages.limitExceeds };
    } else if (result) {
      return { status: 1, data: result.Id };
    } else {
      return { status: 0, data: null, error: messages.somethingwentWrong };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "addSiteTag",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "addSiteTag",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_postNewTag}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function deleteSiteTag(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.tm_deleteTag, {
      name: "deleteSiteTag",
      body: params,
    });
    let result = JSON.parse(data[0].jsonResult)[0];
    if (result.Code == tagManagerConfig.alreadyUsed) {
      return { status: 0, data: null, error: messages.tagAlreadyUsed };
    } else if (result) {
      return { status: 1, data: null };
    } else {
      return { status: 0, data: null, error: messages.somethingwentWrong };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "deleteSiteTag",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "deleteSiteTag",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_deleteTag}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function getHistory(params) {
  try {
    const { data } = await ajax.get(path.tm_getHistory, {
      name: "getHistory",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getHistory",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "Tagmanager-History.vue",
        actionName: "getHistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_getHistory}`,
        moduleName: "Tag Manager History",
        isApi: 0,
      },
    });

    return [];
  }
}

async function getTagHistory(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data, statusCode } = await ajax.get(path.tm_editFromHistory, {
      name: "getTagHistory",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    if (statusCode == responseStatus.NotFound) {
      return { status: 0, data: [], error: messages.somethingwentWrong };
    }

    let resData = JSON.parse(data);

    return { status: 1, data: resData[0] };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: [], error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getTagHistory",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "getTagHistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_editFromHistory}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: [], error: error };
  }
}

async function getExportPreviewData(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.get(path.tm_exportPreviewData, {
      name: "getExportPreviewData",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getExportPreviewData",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "getExportPreviewData",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_exportPreviewData}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return [];
  }
}

async function submitTagForm(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.tm_postTagManager, {
      name: "submitTagForm",
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "submitTagForm",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "submitTagForm",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_postTagManager}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return [];
  }
}
async function updateStatus(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    await ajax.post(path.tm_updateStatus, {
      name: "updateStatus",
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return;
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "updateStatus",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "Update Tag Status",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.tm_updateStatus}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });
  }
}

export default service;
