const Index = () => import("./Index");
const TagManager = () => import("./components/TagManager");
const History = () => import("./components/History");

import auth from "@/router/middleware/auth";
import checkUserPermission from "@/router/middleware/checkUserPermission";
import maintenance from "@/router/middleware/maintenance";

const tagRoute = {
  path: "",
  component: Index,
  children: [
    {
      path: "/tag-manager",
      name: "tag-manager",
      meta: {
        label: "Tag Manager",
        controlLink: "tag-manager",
        languageSlug: "tag-manager",
        module: "tag",
        moduleMenuId: "tagMenu",
        moduleLabel: "Tag Manager",
        moduleLanguageSlug: "tag-manager",
        permissionName: "tag-manager-access",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: TagManager,
    },
    {
      path: "/tag-history",
      name: "tag-history",
      meta: {
        label: "History",
        controlLink: "tag-history",
        languageSlug: "tag-history",
        module: "tag",
        moduleMenuId: "tagMenu",
        moduleLanguageSlug: "tag-manager",
        moduleLabel: "Tag Manager",
        middleware: [maintenance, auth, checkUserPermission],
      },
      component: History,
    },
  ],
};

export default tagRoute;
