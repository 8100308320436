<template>
  <!-- Page Wrapper -->
  <b-overlay :show="isLoading" rounded="sm" @shown="onShown" @hidden="onHidden">
    <div id="wrapper" class="wrapper d-flex w-100 min-vh-100">
      <!-- Sidebar -->
      <sidebar class="d-none d-lg-block d-xl-block" :userPanel="true" />
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <!-- d-flex flex-column  -->
      <div id="content-wrapper">
        <!-- Topbar -->
        <top-navigation :userPanel="true" />
        <!-- End of Topbar -->
        <!-- Begin Page Content -->
        <!-- Main Content -->
        <div class="content">
          <portal-target name="breadcrumb">
            <bread-crumb
              :activeModule="$t(`general.${$route.meta.moduleLanguageSlug}`)"
              :activePage="$t(`general.${$route.meta.languageSlug}`)"
              :activePageUrl="$route.path"
              v-if="showBreadCrumb"
            />
          </portal-target>
          <router-view />
        </div>
        <!-- End of Main Content -->
      </div>
      <!-- <b-overlay
      :show="isLoading"
      class="position-fixed"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
      no-wrap
    /> -->
    </div>
  </b-overlay>
</template>

<script>
import { eventBus } from "../main";
import { mapGetters, mapMutations } from "vuex";

import Sidebar from "../components/Sidebar.vue";
import TopNavigation from "../components/TopNavigation.vue";
import BreadCrumb from "../components/BreadCrumb.vue";

export default {
  name: "LayoutDefault",
  components: {
    Sidebar,
    TopNavigation,
    BreadCrumb,
  },
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  created() {
    eventBus.$on("open-mobilenav", (data) => {
      this.isMobileNavOpen = data;
    });
  },
  mounted() {
    this.$nextTick(() => {
      document
        .querySelector("html")
        .setAttribute("lang", this.selected_language);
    });
  },
  watch: {
    $route(to) {
      eventBus.$emit("close-mobilenav", {
        name: to.name,
        module: to.meta.module,
        moduleMenuId: to.meta.moduleMenuId,
      });
    },
    selected_language(newValue) {
      document.querySelector("html").setAttribute("lang", newValue);
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    showBreadCrumb() {
      let routeNames = ["home", "help", "permission-denied"];

      if (routeNames.includes(this.$route.name)) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    onShown() {
      // Disabled the body scroll when the overlay is showing
      document.body.classList.add("overflow-hidden");
    },
    onHidden() {
      // Enabled thhe body scroll when the overlay is removed
      document.body.classList.remove("overflow-hidden");
    },
  },
  onIdle() {
    this.logout();
  },
};
</script>
