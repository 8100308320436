const getters = {
  tagManagerId: (state) => state.tagManagerId,
  siteTags: (state) => state.siteTags,
  selectedTag: (state) => state.selectedTag,
  history: (state) => state.history,
  mode: (state) => state.mode,
  preFilledData: (state) => state.preFilledData,
  isAddNewTagFailed: (state) => state.isAddNewTagFailed,
  isAddNewTagFailedError: (state) => state.isAddNewTagFailedError,
};

export default getters;
