const state = {
  tagManagerId: 0,
  siteTags: [],
  selectedTag: null,
  history: [],
  mode: "",
  preFilledData: null,
  isAddNewTagFailed: false,
  isAddNewTagFailedError: "",
};

export default state;
