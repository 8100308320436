import { camelCase } from "lodash";

const guessDelimiters = (text, possibleDelimiters) => {
  return possibleDelimiters.filter((delimiter) => {
    var cache = -1;
    return text.split("\n").every((line) => {
      if (!line) {
        return true;
      }

      var length = line.split(delimiter).length;
      if (cache < 0) {
        cache = length;
      }
      return cache === length && length > 1;
    });
  });
};

const CSVToJSON = (csvData, delimiter = []) => {
  var data = CSVToArray(csvData, delimiter[0]);
  var objData = [];
  for (var i = 1; i < data.length; i++) {
    objData[i - 1] = {};
    for (var k = 0; k < data[0].length && k < data[i].length; k++) {
      var key = camelCase(data[0][k]);
      objData[i - 1][key] = data[i][k];
    }
  }
  var jsonData = JSON.stringify(objData);
  jsonData = jsonData.replace(/},/g, "},\r\n");
  return jsonData;
};

const CSVToArray = (csvData, delimiter) => {
  delimiter = delimiter || "\t";
  var pattern = new RegExp(
    "(\\" +
      delimiter +
      "|\\r?\\n|\\r|^)" +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      delimiter +
      "\\r\\n]*))",
    "gi"
  );
  var data = [[]];
  var matches = null;
  while ((matches = pattern.exec(csvData))) {
    let matchedDelimiter = matches[1];
    if (matchedDelimiter.length && matchedDelimiter != delimiter) {
      data.push([]);
    }
    if (matches[2]) {
      matchedDelimiter = matches[2].replace(new RegExp('""', "g"), '"');
    } else {
      matchedDelimiter = matches[3];
    }

    data[data.length - 1].push(matchedDelimiter);
  }
  return data;
};

export default {
  CSVToJSON,
  guessDelimiters,
};
