import { eventBus } from "@/main";
import ajax from "@/customApi";
import config from "../../config";
const qs = require("qs");
import { clone, forEach, orderBy } from "lodash";

const { path, filterErrorObject, productGroupObject, messages } = config;

const actions = {
  resetSavedLanguage({ commit }) {
    commit("resetSavedLanguage");
  },
  getStatusCode(context) {
    ajax
      .get(path.getStatusCode, {
        name: "getStatusCode",
      })
      .then((response) => {
        const { data } = response;

        context.commit("setItem", {
          resource: "statusCode",
          payload: data,
        });
      })
      .catch((error) => {
        let user = context.getters["auth/user"];

        if (error.response.status === 401) {
          eventBus.$emit("apiError", messages.unauthorizedUser);
          return;
        } else eventBus.$emit("apiError", error.message);

        ajax.post(path.exception, {
          name: "getStatusCode",
          body: {
            id: 0,
            userId: user.Id,
            exceptionMessage: error.response.data,
            controllerName: "",
            actionName: "getStatusCode",
            // exceptionStackTrace: error.stack,
            exceptionStackTrace: `API : ${path.getStatusCode}`,
            moduleName: "General",
            isApi: 0,
          },
        });
      });
  },
  async getLanguages(context) {
    const { data } = await ajax.get(path.getLanguages, {
      name: "languages",
    });

    context.commit("setItem", {
      resource: "languages",
      payload: data,
    });

    return data;
  },
  async getTranslations(context, params) {
    const { data } = await ajax.get(path.getTranslations, {
      name: "translations",
      params: params,
    });

    let jsonResult = JSON.parse(data[0].jsonResult);

    const languages = context.getters["languages"];

    let translations = Object.create(null);

    forEach(jsonResult, (element) => {
      let moduleName = "";
      switch (element.ModuleName) {
        case "Everyday pricing access":
          moduleName = "everyday-pricing";
          break;
        case "Everyday Pricing Import":
          moduleName = "everyday-pricing-import";
          break;
        case "Promotional pricing access":
          moduleName = "promotional-pricing";
          break;
        case "Promotional Pricing Import":
          moduleName = "promotional-pricing-import";
          break;
        case "Flyer pricing access":
          moduleName = "flyer-pricing-import";
          break;
        case "Flat flyer pricing access":
          moduleName = "flat-flyer-pricing";
          break;
        case "Active promotion access":
          moduleName = "active-promotion";
          break;
        case "Pricing History":
          moduleName = "pricing-history";
          break;
        case "Tag manager access":
          moduleName = "tag-manager";
          break;
        case "Tag History":
          moduleName = "tag-history";
          break;
        case "Profile":
          moduleName = "profile";
          break;
        case "Change Password":
          moduleName = "change-password";
          break;
        case "User Dashboard":
          moduleName = "user-dashboard";
          break;
        default:
          moduleName = "general";
          break;
      }

      let obj = Object.create(null);

      forEach(element.FieldDetails, (_element) => {
        obj[_element.Slug] = _element.FiledDetailValue;
      });

      translations[moduleName] = obj;
    });

    let messages = Object.create(null);

    forEach(languages, (language) => {
      messages[language.languageCode] = {};

      if (language.languageId == params.langId) {
        messages[language.languageCode] = translations;
      }
    });

    context.commit("setItem", {
      resource: "translations",
      payload: messages,
    });
  },
  async getModuleMasterList() {
    try {
      const { data } = await ajax.get(path.getmodulemasterlist, {
        name: "getModuleMasterList",
      });

      return data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
      }
      return {};
    }
  },
  async getUserModulerPermission(context) {
    let user = context.getters["auth/user"];
    try {
      const { data } = await ajax.get(path.getUsermodulerPermissionById, {
        name: "getUserModulerPermission",
        params: {
          userId: user.id,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
      }
      return {};
    }
  },
  async getVendorFilters(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;

    try {
      const { data } = await ajax.get(path.getVendor, {
        name: "getVendor",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      if (data.length > 0) {
        context.commit("setItem", { resource: "vendorFilters", payload: data });
      } else {
        let _filterErrorObject = Object.assign({}, filterErrorObject);
        _filterErrorObject.vendorFilter = true;
        context.commit("setItem", {
          resource: "filterError",
          payload: _filterErrorObject,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getVendorFilters",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "getVendorFilters",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getVendor}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getCategoriesFilters(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;

    try {
      const { data } = await ajax.get(path.getCategory, {
        name: "getCategory",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      if (data.length > 0) {
        context.commit("setItem", {
          resource: "categoriesFilters",
          payload: data,
        });
      } else {
        let _filterErrorObject = Object.assign({}, filterErrorObject);
        _filterErrorObject.categoriesFilter = true;
        context.commit("setItem", {
          resource: "filterError",
          payload: _filterErrorObject,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);
      ajax.post(path.exception, {
        name: "getCategoriesFilters",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "getCategory",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getCategory}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getProductTagFilters(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;

    try {
      const { data } = await ajax.get(path.getProductTag, {
        name: "getProductTag",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      if (data.length > 0) {
        context.commit("setItem", {
          resource: "productTagFilters",
          payload: data,
        });
      } else {
        let _filterErrorObject = Object.assign({}, filterErrorObject);
        _filterErrorObject.productTagFilter = true;
        context.commit("setItem", {
          resource: "filterError",
          payload: _filterErrorObject,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getProductTag",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "getProductTag",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getProductTag}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getVmnFilters(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;
    try {
      const { data } = await ajax.get(path.getVmn, {
        name: "getVmn",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      if (data.length > 0) {
        context.commit("setItem", { resource: "vmnFilters", payload: data });
      } else {
        let _filterErrorObject = Object.assign({}, filterErrorObject);
        _filterErrorObject.vmnFilter = true;
        context.commit("setItem", {
          resource: "filterError",
          payload: _filterErrorObject,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getVmn",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "getVmn",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getVmn}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getSkuFilters(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;
    try {
      const { data } = await ajax.get(path.getSkuVmn, {
        name: "getSku",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      if (data.length > 0) {
        context.commit("setItem", { resource: "skuFilters", payload: data });
      } else {
        let _filterErrorObject = Object.assign({}, filterErrorObject);
        _filterErrorObject.skuFilter = true;
        context.commit("setItem", {
          resource: "filterError",
          payload: _filterErrorObject,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getSku",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "getSku",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getSkuVmn}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  setProductGroups(context, payload) {
    let data = [];
    if (payload.type != "reset") {
      data = payload.data;
    } else {
      let _productGroupObject = clone(productGroupObject);
      data = [_productGroupObject];
    }

    context.commit("setProductGroups", data);
  },
  addNewProductGroup(context, groupId) {
    let _productGroupObject = Object.assign({}, productGroupObject);
    let productGroup = { ..._productGroupObject, groupId: groupId };
    context.commit("addNewProductGroup", productGroup);
  },
  updateProductGroups(context, payload) {
    context.commit("updateProductGroups", payload);
  },
  resetFormState(context) {
    let _filterErrorObject = Object.assign({}, filterErrorObject);
    context.commit("setItem", { resource: "selectedSite", payload: null });
    context.commit("setItem", { resource: "vendorFilters", payload: [] });
    context.commit("setItem", { resource: "categoriesFilters", payload: [] });
    context.commit("setItem", { resource: "productTagFilters", payload: [] });
    context.commit("setItem", { resource: "vmnFilters", payload: [] });
    context.commit("setItem", { resource: "skuFilters", payload: [] });
    context.dispatch("setProductGroups", { type: "reset" });
    context.commit("setItem", {
      resource: "filterError",
      payload: _filterErrorObject,
    });
    context.commit("setItem", {
      resource: "filteredProductError",
      payload: false,
    });
    context.commit("setItem", { resource: "filteredProducts", payload: [] });
  },
  async getPricingFilteredProducts(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;

    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      context.commit("setItem", {
        resource: "filteredProductError",
        payload: false,
      });
      context.commit("setItem", { resource: "filteredProducts", payload: [] });

      const { data } = await ajax.post(path.getFilteredProducts, {
        name: "getPricingFilteredProducts",
        body: payload,
      });

      context.commit("setItem", { resource: "isLoading", payload: false });

      if (data.length > 0 && data[0].pricingManagerId > 0) {
        context.commit("pricingManager/setItem", {
          resource: "pricingManagerId",
          payload: data[0].pricingManagerId,
        });
        context.commit("setItem", {
          resource: "filteredProducts",
          payload: data,
        });
      } else {
        context.commit("setItem", {
          resource: "filteredProductError",
          payload: true,
        });
      }
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      context.commit("setItem", {
        resource: "filteredProductError",
        payload: true,
      });

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getPricingFilteredProducts",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "everyday",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getFilteredProducts}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getEverydayImportFilteredProducts(context, payload) {
    let { exceptionParams } = payload;

    delete payload.exceptionParams;

    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      context.commit("setItem", {
        resource: "filteredProductError",
        payload: false,
      });
      context.commit("setItem", { resource: "filteredProducts", payload: [] });

      const { data } = await ajax.post(path.getFilteredProducts, {
        name: "getEverydayImportFilteredProducts",
        body: payload,
      });

      context.commit("setItem", { resource: "isLoading", payload: false });

      if (data.length > 0 && data[0].pricingManagerId > 0) {
        context.commit("pricingManager/setItem", {
          resource: "pricingManagerId",
          payload: data[0].pricingManagerId,
        });
        context.commit("setItem", {
          resource: "filteredProducts",
          payload: data,
        });
      } else {
        context.commit("setItem", {
          resource: "filteredProductError",
          payload: true,
        });
      }
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      context.commit("setItem", {
        resource: "filteredProductError",
        payload: true,
      });

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getEverydayImportFilteredProducts",
        body: {
          id: 0,
          userId: exceptionParams.userId,
          exceptionMessage: error.response.data,
          controllerName: exceptionParams.controllerName,
          actionName: "everyday",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getFilteredProducts}`,
          moduleName: exceptionParams.moduleName,
          isApi: 0,
        },
      });
    }
  },
  async getTagFilteredProducts(context, payload) {
    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      context.commit("setItem", {
        resource: "filteredProductError",
        payload: false,
      });
      context.commit("setItem", { resource: "filteredProducts", payload: [] });

      const { data } = await ajax.post(path.tm_FilteredProducts, {
        name: "getTagFilteredProducts",
        body: payload,
      });

      context.commit("setItem", { resource: "isLoading", payload: false });

      if (data.length > 0) {
        if (data[0].productTagManagerId == 0) {
          let statusCode = context.getters["statusCode"];

          let currentStatus = statusCode.find(
            (element) => element.code == data[0].statusId
          );

          if (currentStatus.reason == "Duplicate") {
            return {
              status: 0,
              data: null,
              error: messages.tagAlreadyUsedInForm,
            };
          } else if (currentStatus.reason == "DRSGAlreadyUseThisTag") {
            return { status: 0, data: null, error: messages.tagAlreadyUsed };
          } else {
            return {
              status: 0,
              data: null,
              error: messages.somethingwentWrong,
            };
          }
        } else if (data[0].productTagManagerId > 0) {
          context.commit("tagManager/setItem", {
            resource: "tagManagerId",
            payload: data[0].productTagManagerId,
          });
          context.commit("setItem", {
            resource: "filteredProducts",
            payload: data,
          });

          return { status: 1, data: data };
        }
      } else {
        context.commit("setItem", {
          resource: "filteredProductError",
          payload: true,
        });

        return { status: 0, data: null, error: messages.somethingwentWrong };
      }
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getTagFilteredProducts",
        body: {
          id: 0,
          userId: payload.userId,
          exceptionMessage: error.response.data,
          controllerName: "TagManager.vue",
          actionName: "producttagmanager",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.tm_FilteredProducts}`,
          moduleName: "Tag Manager",
          isApi: 0,
        },
      });
    }
  },
  async getHelpDocument(context, languageId = 1) {
    try {
      const { data } = await ajax.get(path.setUserManual, {
        name: "getHelpDocument",
        params: { languageId: languageId },
      });

      return data;
    } catch (error) {
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getHelpDocument",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "Help",
          actionName: "UserManual",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.setUserManual}`,
          moduleName: "Help",
          isApi: 0,
        },
      });
    }
  },
  async saveHelpDocument(context, payload) {
    try {
      await ajax.post(path.setUserManual, {
        name: "saveHelpDocument",
        body: payload,
      });
    } catch (error) {
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "saveHelpDocument",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "Help",
          actionName: "Update UserManual",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.setUserManual}`,
          moduleName: "Help",
          isApi: 0,
        },
      });
    }
  },
  async getDashboardProductsCount(context, payload) {
    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      const { data } = await ajax.get(path.getDashboardProductsCount, {
        name: "getDashboardProductsCount",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });
      context.commit("setItem", { resource: "isLoading", payload: false });
      return data;
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getDashboardProductsCount",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "Home.vue",
          actionName: "Get Product COunt",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getDashboardProductsCount}`,
          moduleName: "Userpanel Dashboard",
          isApi: 0,
        },
      });
    }
  },
  async getVersionHistory(context, languageId = 1) {
    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      const { data } = await ajax.get(path.getVersionHistory, {
        name: "getVersionHistory",
        params: { languageId },
      });
      context.commit("setItem", { resource: "isLoading", payload: false });

      let proccess_data = orderBy(
        data,
        (i) => new Date(i.deploymentDate),
        "desc"
      ).map((item) => {
        let d = new Date(item.deploymentDate);
        return { ...item, month: d.getMonth(), year: d.getFullYear() };
      });

      context.commit("setItem", {
        resource: "versionHistory",
        payload: proccess_data,
      });
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getVersionHistory",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "VersionHistory.vue",
          actionName: "Get Version History",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getVersionHistory}`,
          moduleName: "Version History",
          isApi: 0,
        },
      });
    }
  },
  async getProductsActivity(context, payload) {
    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      const { data } = await ajax.get(path.getProductsActivity, {
        name: "getProductsActivity",
        params: payload,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });
      context.commit("setItem", { resource: "isLoading", payload: false });
      return data;
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getProductsActivity",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "Home.vue",
          actionName: "Get Products Activity",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getProductsActivity}`,
          moduleName: "Userpanel Dashboard",
          isApi: 0,
        },
      });
    }
  },
  async getActivityFeedDetails(context, payload) {
    try {
      context.commit("setItem", { resource: "isLoading", payload: true });
      const { data } = await ajax.post(path.getActivityFeedDetails, {
        name: "getActivityFeedDetails",
        body: payload,
      });
      context.commit("setItem", { resource: "isLoading", payload: false });
      return data;
    } catch (error) {
      context.commit("setItem", { resource: "isLoading", payload: false });
      let user = context.getters["auth/user"];

      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "getProductsActivity",
        body: {
          id: 0,
          userId: user.Id,
          exceptionMessage: error.response.data,
          controllerName: "Home.vue",
          actionName: "Get Products Activity Details",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.getActivityFeedDetails}`,
          moduleName: "Userpanel Dashboard",
          isApi: 0,
        },
      });
    }
  },
};

export default actions;
