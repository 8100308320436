<template>
  <div class="breadcrums">
    <ul
      class="list-unstyled d-flex align-items-md-center flex-md-row flex-column"
    >
      <li>{{ activeModule }}</li>
      <template v-if="showExtraItem == false">
        <li>
          <a :href="activePageUrl">{{ activePage }}</a>
        </li>
      </template>
      <template v-else>
        <li>{{ activePage }}</li>
        <li>
          <a :href="activePageUrl">{{ extraItem }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BreadCrumb",
  props: ["activeModule", "activePage", "activePageUrl"],
  computed: {
    ...mapGetters("userManager", {
      currentUserProfile: "user",
    }),
    showExtraItem() {
      if (this.$route.name == "edit-users") {
        return true;
      } else {
        return false;
      }
    },
    extraItem() {
      if (this.$route.name == "edit-users") {
        if (this.currentUserProfile)
          return `${this.currentUserProfile.firstName} ${this.currentUserProfile.lastName}`;
        else return "";
      } else {
        return "";
      }
    },
  },
};
</script>
