const state = {
  gridList: [],
  sortBy: "CreatedOn",
  sortOrder: "DESC",
  store: {},
  searchQuery: "",
  tblLoader: false,
  formLoader: false,
  storeList: [],
};

export default state;
