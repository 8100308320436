import ajax from "@/customApi";
const qs = require("qs");
import config from "../../../../config";
import { eventBus } from "@/main";
const { path, messages } = config;

const service = {
  getSiteSettings,
  manageSiteSettings,
  getSiteVendorCategory,
  manageSiteVendorCategoryMapping,
};

async function getSiteSettings(params) {
  try {
    const { data } = await ajax.get(path.getSiteSettings, {
      name: "get site settings",
      params: { siteId: params.id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "get site settings",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "VendorCategoriesList.vue",
        actionName: "getSiteSettings",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getSiteSettings}`,
        moduleName: "VendorCategoriesList",
        isApi: 0,
      },
    });
    return [];
  }
}

async function manageSiteSettings(params) {
  let { userId } = params;
  delete params.userId;
  try {
    const data = await ajax.post(path.manageSiteSettings, {
      name: "manage site settings",
      body: params,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "manage site settings",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "VendorCategoriesList.vue",
        actionName: "manageSiteSettings",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.manageSiteSettings}`,
        moduleName: "VendorCategoriesList",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getSiteVendorCategory(params) {
  try {
    const { data } = await ajax.get(path.getSideVendorCategory, {
      name: "Vendor Category",
      params: { SiteId: params.id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "Vendor Category",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "VendorCategoriesList.vue",
        actionName: "getSiteVendorCategory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getSideVendorCategory}`,
        moduleName: "VendorCategoriesList",
        isApi: 0,
      },
    });
    return [];
  }
}

async function manageSiteVendorCategoryMapping(params) {
  try {
    const { data } = await ajax.post(path.manageSiteVendorCategoryMapping, {
      name: "Update Vendor Category",
      body: params.vendorDataChanges,
      params: { siteId: params.siteId },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "Update Vendor Category",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "VendorCategoriesList.vue",
        actionName: "manageSiteVendorCategoryMapping",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.manageSiteVendorCategoryMapping}`,
        moduleName: "VendorCategoriesList",
        isApi: 0,
      },
    });
    return [];
  }
}

export default service;
