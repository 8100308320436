const getters = {
  gridList(state) {
    return state.gridList;
  },
  store(state) {
    return state.store;
  },
  searchQuery(state) {
    return state.searchQuery;
  },
  tblLoader(state) {
    return state.tblLoader;
  },
  sortBy(state) {
    return state.sortBy;
  },
  sortOrder(state) {
    return state.sortOrder;
  },
  storeList(state) {
    return state.storeList;
  },
};

export default getters;
