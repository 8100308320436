import Vue from "vue";

import csvHelpers from "./csv-helpers";

export default {
  install: () => {
    Vue.prototype.csvHelpers = csvHelpers;
    Vue.csvHelpers = csvHelpers;
  },
};
