<template>
  <nav id="sidebar" class="vh-100">
    <div class="sidebar-header px-3">
      <b-link :to="userPanel ? { name: 'home' } : { name: 'admin-home' }">
        <div class="d-flex align-items-center justify-content-center">
          <b-img
            src="/media/images/drsg-logo.svg"
            class="brand-image"
            alt="DRSG image"
          ></b-img>
          <h5
            class="mb-0 mt-1 ml-2 text-white font-weight-bold"
            v-if="!userPanel"
          >
            Admin
          </h5>
        </div>
      </b-link>
    </div>

    <navigation-links
      :navigationMenu="sidebarNavigation"
      :isAdminNavigation="!userPanel"
    />

    <footer v-if="userPanel">
      <ul class="list-unstyled p-0 m-0">
        <li class="nav-item direct-link">
          <b-link :to="helpRoute.route" class="help-link">
            <i class="mr-2" :class="`icon-${helpRoute.icon}`"></i>
            <span>{{ $t(`general.${helpRoute.languageSlug}`) }}</span>
          </b-link>
        </li>
      </ul>
    </footer>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config";
import NavigationLinks from "./NavigationLinks.vue";
import { forEach } from "lodash";
const { helpRoute, adminNavigationMenu } = config;

export default {
  name: "Sidebar",
  components: {
    NavigationLinks,
  },
  props: {
    userPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpRoute: helpRoute,
    };
  },
  computed: {
    ...mapGetters("auth", ["userModulerPermission"]),
    sidebarNavigation() {
      if (this.userPanel) {
        let navigationMenu = Object.assign({}, config.navigationMenu);
        if (this.userModulerPermission) {
          forEach(navigationMenu, (menu) => {
            if (menu.module == "pricing") {
              let isAllowed = false;
              forEach(
                [
                  "active-promotion-access",
                  "everyday-pricing-access",
                  "flat-flyer-pricing-access",
                  "flyer-pricing-access",
                  "promotional-pricing-access",
                ],
                (module) => {
                  if (this.userModulerPermission[module]) {
                    isAllowed = true;
                    return false;
                  }
                }
              );

              menu.isAllowed = isAllowed;

              forEach(menu.routes, (route) => {
                if (route.slug == "pricing-history") {
                  let tmp_modulerPermission = Object.assign(
                    {},
                    this.userModulerPermission
                  );

                  forEach(
                    [
                      "flat-flyer-pricing-access",
                      "active-promotion-access",
                      "tag-manager-access",
                      "store-location-access",
                      "recycle-fee-access",
                    ],
                    (module) => {
                      delete tmp_modulerPermission[module];
                    }
                  );

                  let historyAllowed = Object.values(
                    tmp_modulerPermission
                  ).some((value) => value === true);

                  // route.isAllowed = menu.isAllowed;
                  route.isAllowed = historyAllowed;
                } else if (isAllowed == false) {
                  route.isAllowed = false;
                } else {
                  route.isAllowed =
                    this.userModulerPermission[route.permissionName];
                }

                if (route.submenu) {
                  forEach(route.submenu, (submenu_route) => {
                    if (route.isAllowed == false) {
                      submenu_route.isAllowed = false;
                    } else {
                      submenu_route.isAllowed =
                        this.userModulerPermission[
                          submenu_route.permissionName
                        ];
                    }
                  });
                }
              });
            } else if (menu.module == "tag") {
              if (this.userModulerPermission["tag-manager-access"] == true) {
                menu.isAllowed = true;
              } else {
                menu.isAllowed = false;
              }
            }
          });
        }
        return navigationMenu;
      } else {
        let navigationMenu = adminNavigationMenu;
        return navigationMenu;
      }
    },
  },
};
</script>
