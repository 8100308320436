<template>
  <!-- Page Wrapper -->
  <b-overlay :show="isLoading" rounded="sm" @shown="onShown" @hidden="onHidden">
    <div id="wrapper" class="wrapper d-flex w-100 min-vh-100">
      <!-- Sidebar -->
      <sidebar class="d-none d-lg-block d-xl-block" />
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="w-100">
        <!-- Topbar -->
        <top-navigation />
        <!-- End of Topbar -->
        <!-- Begin Page Content -->

        <!-- Main Content -->
        <div class="content">
          <portal-target name="breadcrumb">
            <bread-crumb
              :activeModule="$route.meta.moduleLabel"
              :activePage="$route.meta.label"
              :activePageUrl="$route.path"
              v-if="showBreadCrumb"
            />
          </portal-target>
          <router-view />
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- <b-overlay
      :show="isLoading"
      class="position-fixed"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
      no-wrap
    /> -->
    </div>
  </b-overlay>
</template>

<script>
import { eventBus } from "../main";
import { mapGetters, mapMutations } from "vuex";
import Sidebar from "../components/Sidebar.vue";
import TopNavigation from "../components/TopNavigation.vue";
import BreadCrumb from "../components/BreadCrumb.vue";

export default {
  name: "AdminLayout",
  components: {
    Sidebar,
    TopNavigation,
    BreadCrumb,
  },
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  created() {
    eventBus.$on("open-mobilenav-admin", (data) => {
      this.isMobileNavOpen = data;
    });
  },
  watch: {
    $route(to) {
      eventBus.$emit("close-mobilenav", {
        name: to.name,
        module: to.meta.module,
        moduleMenuId: to.meta.moduleMenuId,
      });
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    showBreadCrumb() {
      // if (this.$route.name != "admin-home") return true;
      // else return false;

      let _show;

      switch (this.$route.name) {
        case "admin-home":
          _show = false;
          break;
        case "users-list":
          _show = false;
          break;
        default:
          _show = true;
          break;
      }

      return _show;
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    onShown() {
      // Disabled the body scroll when the overlay is showing
      document.body.classList.add("overflow-hidden");
    },
    onHidden() {
      // Enabled thhe body scroll when the overlay is removed
      document.body.classList.remove("overflow-hidden");
    },
  },
  onIdle() {
    this.logout();
  },
};
</script>
