const getters = {
  languages: (state) => state.languages,
  selected_language: (state) => state.selected_language,
  translations: (state) => state.translations,
  userDashboardSite: (state) => state.userDashboardSite,
  selectedSite: (state) => state.selectedSite,
  isLoading: (state) => state.isLoading,
  loadingFilters: (state) => state.loadingFilters,
  vendorFilters: (state) => state.vendorFilters,
  categoriesFilters: (state) => state.categoriesFilters,
  productTagFilters: (state) => state.productTagFilters,
  vmnFilters: (state) => state.vmnFilters,
  skuFilters: (state) => state.skuFilters,
  filterError: (state) => state.filterError,
  productGroups: (state) => state.productGroups,
  statusCode: (state) => state.statusCode,
  filteredProductError: (state) => state.filteredProductError,
  filteredProducts: (state) => state.filteredProducts,
  versionHistory: (state) => state.versionHistory,
};

export default getters;
