const state = {
  pricingManagerId: 0,
  activePromotions: [],
  history: [],
  mode: "",
  preFilledData: null,
  validFlatFlyerData: [],
  validFlyerImportData: [],
  validPromotionalImportData: [],
  errorFlatFlyerData: null,
  validEverydayImportData: [],
};

export default state;
